.game-resume-header {
  border-bottom: 1px #ddd solid;
  padding: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.game-resume-subheader {
  padding: 10px;
}

.game-resume-subheader-image {
  max-width: 100%;
  max-height: 25vh;
  min-height: 100px;

  border-radius: 12px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.game-resume-subheader-text {
  padding-top: 10px;
}

.game-card {
  margin: 0 !important;
}

.game-card>.ui.card {
  margin: 0.5em 0.5em;
}

.game-card>.ui.card>.content {
  padding: 0.5em;
}

.game-card>.ui.card.disabled {
  opacity: 0.45 !important;
}

.billboard {
  width: calc(100% - 16px);
  position: relative;
  margin: 8px;
}

.billboard .billboard-headline {
  position: absolute;
  bottom: 0px;
  padding: 15px 0 15px;
  width: 100%;
  background: linear-gradient(to bottom, #3336, #3336 50%);
}

.billboard .billboard-headline h2 {
  margin: 0 auto;
  color: white;
}


.billboard-header {
  margin: 20px 10px 10px 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: 1.5rem;
}

.slider-header {
  margin: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: 1.5rem;
}

.superslider {
  padding: 0 8px;
}

@media (hover: none) and (pointer: coarse) {
  /* hide scrollbar on touch screens */
  .superslider::-webkit-scrollbar {
    display: none;
  }
}

.superslide {
  white-space: normal;
  padding: 4px;
  display: inline-block;
  width: 170px;
}

.superslide .ui.card .content {
  padding: 8px;
}

.superslide .superslide-title {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  line-height: 28px;
  /* fallback */
  height: 84px;
  /* fallback */
  -webkit-line-clamp: 3;
  /* number of lines to show */
  -webkit-box-orient: vertical;
}

.superslide .ui.card>.image>img {
  object-fit: cover;
  height: 120px;
}