html,
body {
  font-family: "Lucida Console", Monaco, monospace;
}

#app {
  height: 100vh; /* Fallback for browsers that do not support Custom Properties */
  height: calc(var(--vh, 1vh) * 100);
}

#app > .app,
#app > .app > .dimmable,
#app > .app > .dimmable > .dimmable,
#app > .app > .dimmable > .dimmable > .game,
#app > .app > .dimmable > .dimmable > .game > .ui.container {
  height: 100%;
}

.game {
  position: relative;
  background-repeat: no-repeat;
  background-position-x: center;
  background-attachment: fixed;

  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}
