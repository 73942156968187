.game-container,
.game-container > .ui.container {
  height: 100%;
}

.game-container {
  position: relative;
  background-repeat: no-repeat;
  background-position-x: center;
  background-attachment: fixed;

  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}
.game-container-grid {
  height: 100%;
  margin: 0 -1em !important;
  background: #fcfbff;
  padding: 0px !important;
}
.game-container-grid-column {
  padding: 0px !important;
  display: flex !important;
  flex-direction: column;
  height: 100%;
}
