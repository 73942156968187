.game-detail-header-container {
  border-bottom: 1px #ddd solid;
  padding: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.ui.header.game-detail-header {
  text-transform: uppercase;
  flex-grow: 1;
  margin: 0;
  font-weight: 400;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-family: 'Open Sans', sans-serif;
}

.billboard {
  width: calc(100% - 16px);
  position: relative;
  margin: 8px;
}

.billboard .billboard-headline {
  position: absolute;
  bottom: 0px;
  padding: 15px 0 15px;
  width: 100%;
  background: linear-gradient(to bottom, #3336, #3336 50%);
}

.billboard .billboard-headline h2 {
  margin: 0 auto;
  color: white;
}

.billboard-header-text {
  text-transform: capitalize;
}

.difficulty-container {
  padding: 10px;
  display: flex;
  flex-direction: row;
}

.difficulty-label {
  margin-right: 10px;
}

.difficulty-label span{
  text-transform: uppercase;
}

.game-detail-description {
  padding: 10px;
  text-align: justify;
}

.game-detail-call-to-action-container{
  margin-bottom: 16px;
}

.game-detail-call-to-action-container span{
  text-transform: uppercase;
}