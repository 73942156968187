/* Container */
.game-home-container,
.game-home-container > .ui.container {
  height: 100%;
}

.game-home-container {
  position: relative;
  background-repeat: no-repeat;
  background-position-x: center;
  background-attachment: fixed;

  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}
.game-home-container-grid {
  height: 100%;
  margin-bottom: 0 !important;
  margin-top: 0 !important;
}
.game-home-container-grid-column {
  display: flex !important;
  flex-direction: column;
}
.game-home-header {
  margin-bottom: 20px !important;
}

/* Home/Login */
.game-home-desc {
  overflow-y: auto;
  flex: 1;
  padding: 0 0 5px 0;
}

.game-home-desc,
.game-home-desc-modal {
  text-align: justify;
}

/* Login */
.ui.form .field.login-password-input {
  margin-bottom: 5px;
}

.game-home-login-forgot-link {
  text-align: left;
  margin-bottom: 8px;
}

.not-already-registered {
  margin-top: 8px;
  margin-bottom: 8px;
}

/* Forgot */
.game-home-login-forgot {
  margin: auto;
  width: 100%;
}

/* Reset */
.game-home-login-reset {
  margin: auto;
  width: 100%;
}
