/** ChatHeader **/
.chat-header {
  border-bottom: 1px #ddd solid;
  padding: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

/** ChatFooter **/
.chat-footer {
  min-height: 56px;
  border-top: 1px #ddd solid;
}

/** ChatFooter **/
.chat-content-container {
  overflow-y: auto;
  flex: 1;
  padding: 0 0 5px 0;
}
.chat-content {
  margin: 5px;
}
